import * as path from './data'
import { Login } from "../pages/auth/Login"
import { Main } from '../pages/main/Main'


const routes = [
  {
    path: path.LOGIN,
    element: <Login />
  },
  {
    path: path.MAIN,
    element: <Main />
  }
]

export {routes}