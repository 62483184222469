

const initialState = {
  isAuth: true,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    default: 
      return state;
  }
}

export default authReducer