import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { routes } from '../../../routes/routes'

export const AppRouter = () => {

  const location = useLocation()
  const { isAuth } = useSelector(state => state.auth)

  return (
    <Routes location={location} key={location.pathname}>
      {routes.map((item, i) =>
        <Route
          path={item.path}
          key={item.path}
          element={item.element}
        />
      )}
      <Route path='*' element={<Navigate to={"/"} replace={true} />} />
    </Routes>
  )
}
